<template>
  <div class="app" :class="[theme + '-theme']">
    <the-heading></the-heading>
    <div class="app-content">
      <left-menu></left-menu>
      <keep-alive>
        <router-view class="app-router-view"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import {
  localstorageHelper
} from '@/fmlib'
import LeftMenu from '@/components/base/LeftMenu.vue'
import TheHeading from '@/components/base/TheHeading.vue'
export default {
  components: { LeftMenu, TheHeading },
  name: 'app',
  computed: {
    theme () {
      return this.$store.getters.theme
    }
  },
  created () {
    this.$store.dispatch('setIsPop',  localstorageHelper.getData('is_pop') !== '0')
  }
}
</script>

<style lang="less">
@import '../../styles/global.less';
body, html {height: 100%;}
.app-router-view {
  flex: 1;
  height: 100%;
  overflow: auto;
  background-color: #f9fbff;
}
.fm-title {
  background: rgba(0, 0, 0, 0) !important;
}
.app {
  background-color: @color-background-base;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-content {
  display: flex;
  flex: 1;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}
</style>
