<template>
  <div class="the-heading">
    <router-link :to="$store.getters.clientType === 'web' ? {name: 'home'} : {name: 'wap.menus'}" tag="a" class="the-heading-left">
      <!-- <embed src="/static/logo/eloop-white.gif" v-if="theme === 'white'" height="70%" type="image/svg+xml"/> -->
      <img src="/static/logo/logo.png" v-if="theme === 'white'" height="100%" type="image/svg+xml"/>
      <img src="/static/logo/logo_dark.png" v-else-if="theme === 'dark'" height="100%" type="image/svg+xml"/>
      <img src="/static/logo/logo.png" v-else height="100%" type="image/svg+xml"/>
    </router-link>
    <!-- warp利用position: relative使得hover展开的菜单刚好填充满右侧 -->
    <div class="menu-child-warp">
      <div class="the-heading-center">
        <slot></slot>
      </div>
      <div class="the-heading-right">
        <the-user></the-user>
        <theme-switch></theme-switch>
      </div>
    </div>
  </div>
</template>

<script>
import TheUser from './TheUser'
import ThemeSwitch from './ThemeSwitch'

export default {
  name: 'TheHeading',
  components: {
    TheUser,
    ThemeSwitch
  },
  props: {
    noMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    theme () {
      return this.$store.getters.theme
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
@import '../../styles/theme.less';
.the-heading {
  height: @the-heading-height;
  min-height: @the-heading-height;
  display: flex;
  z-index: 30;
}
.the-heading-left {
  height: 100%;
  display: flex;
  align-items: center;
  // padding-right: 2rem;
  // .logo {
  //   display: block;
  //   width: 91px;
  //   height: 38px;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   background-size: 100%;
  // }
}
.the-heading-center {
  flex: 1;
  height: 100%;
}
.the-heading-right {
  height: 100%;
  display: flex;
  padding-right: 15px;
  align-items: center;
}

.menu-child-warp {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

.white-theme {
  .the-heading {
    background-color: @color-background-white;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .2);
  }
}
.dark-theme {
  .the-heading {
    background-color: @color-background-dark;
  }
}
</style>
