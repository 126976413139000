<style scoped lang="less">
.left-menus {
  background-color: #FFF;
  padding: 0;
  width: 190px;
  height: 100%;
  overflow: auto;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    position: relative;
    span {
      i.iconfont {
        margin-right: 10px;
      }
    }
  }
}
li > span:first-child,
li {
  transition: all .3s;
  color: #657180;
  font-weight: normal;
}
li.is-group {
  & > span:first-child {
    font-weight: normal;
    cursor: pointer;
    position: relative;
    &::after {
      font-family: "iconfont";
      content: "\e7a7";
      color: rgba(0, 0, 0, .45);
      transform-origin: center;
      transition: all 0.3s;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotateX(-180deg);
    }
  }
  &.hide {
    & > span:first-child {
      &::after {
        transform: translateY(-50%) rotateZ(90deg);
      }
    }
    & > ul {
      display: none;
    }
  }
  &.active {
    & > ul {
      display: block;
    }
    & > span:first-child {
      &::after {
        // transform: translateY(-50%) rotateX(-180deg);
      }
    }
  }
}
li > span:first-child {
  cursor: pointer;
  line-height: 50px;
  display: block;
  padding: 0;
  padding-right: 30px;
  padding-left: 20px;
  user-select: none;
}
li.active > span:first-child,
li:hover > span:first-child {
  color: #2F5BEA;
}
li.active > span:first-child {
  background-color: rgba(47, 91, 234, .1);
}
li.active.is-group::after {display: none;}
li.active::after {
  content: '';
  width: 3px;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #2F5BEA;
}
</style>

<template>
  <div class="left-menus">
    <ul>
      <li v-for="menu in menus" :key="menu.name" :class="{
        'is-group': menu.children && menu.children.length,
        'hide': hide.includes(menu.name),
        'active': (current === menu.url || menu.children.findIndex(v => v.url === current) > -1) && !hide.includes(menu.name)
      }">
        <span v-if="!menu.children || !menu.children.length" @click="clickMenu(menu)">
          <i class="iconfont" :class="{[menu.icon || 'icon-app']: true}"></i>{{ menu.name }}
        </span>
        <span v-else @click.self="toogle(menu.name)"><i class="iconfont" :class="{[menu.icon || 'icon-app']: true}"></i>{{ menu.name }}</span>
        <ul>
          <li v-for="subMenu in menu.children" :key="subMenu.name" :class="{ active: current === subMenu.url }"  @click="clickMenu(subMenu)">
            <span style="padding-left: 46px;">{{ subMenu.name }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LeftMenus",
  data() {
    return {
      hide: []
    };
  },
  computed: {
    current() {
      return this.$store.state.route.name
    },
    menus() {
      return this.$store.getters.availableMenus
    }
  },
  watch: {
    menus: {
      handler () {
        this.hide = this.menus.filter(v => v.children && v.children.length).map(v => v.name)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    clickMenu (item) {
      if (item.type === 'sys_page') {
        if (isNaN(item.url)) {
          this.$router.push({name: item.url})
        } else {
          this.$router.push({path: '/page/' + item.url})
        }
      } else if (item.type === 'out_page') {
        this.$store.dispatch('setOutPageUrl', item.url)
        this.$router.push({name: 'business.out_page'})
      } else if (item.type === 'menu' && item.url) {
        this.$router.push({name: item.url})
      }
    },
    toogle(name) {
      if (this.hide.includes(name)) {
        this.hide.splice(this.hide.indexOf(name), 1)
      } else {
        this.hide.push(name)
      }
    }
  }
}
</script>