<template>
  <div class="the-user" v-if="userInfo && userInfo.id">
    <div class="the-user-detail">
      <span class="user-name"><i class="iconfont icon-yonghu-"></i>{{userInfo.name}}</span>
    </div>
    <div class="menu-item-children">
      <a href="javascript:;"
        class="menu-item-children-item" @click="logOut">
        <div class="menu-item-label">注销</div>
      </a>
      <a href="javascript:;"
        class="menu-item-children-item" @click="showC">
        <div class="menu-item-label">修改密码</div>
      </a>
    </div>
    <fm-modal
      title="修改密码"
      :value="showChangePass"
      width="25rem"
      theme="mh-withe"
      :closable="false"
      :cancel="handleClose">
      <div>
        <fm-input class="form-content_item" v-model="dataForm.password" type="password" placeholder="新密码" custom-icon="iconfont icon-mima-old"></fm-input>
        <fm-input style="margin-top: 2rem;" class="form-content_item" v-model="dataForm.rePassword" type="password" placeholder="重复密码" custom-icon="iconfont icon-mima-old"></fm-input>
      </div>
      <div slot="footer" style="display:flex;justify-content: flex-end;">
        <fm-btn
          @click="handleClose">
          取消
        </fm-btn>
        <fm-btn
          style="margin-left: 1rem;"
          @click="savePassword">
          确认修改
        </fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  updatePwd
} from '@/api'
export default {
  name: 'TheUser',
  data () {
    return {
      showChangePass: false,
      dataForm: {
        password: '',
        rePassword: ''
      }
    }
  },
  computed: {
    userInfo: {
      get () {
        return this.$store.getters.userInfo
      }
    }
  },
  methods: {
    savePassword () {
      if (!this.dataForm.password) {
        this.$store.dispatch('showNoticeMsg', {type: 'warning', title: '系统提示', message: '密码不能为空!'})
        return
      }
      if (this.dataForm.password !== this.dataForm.rePassword) {
        this.$store.dispatch('showNoticeMsg', {type: 'warning', title: '系统提示', message: '两次密码输入不一致!'})
        return
      }
      let parm = {
        password: this.dataForm.password
      }
      updatePwd(parm).then(() => {
        this.showChangePass = false
        this.$store.dispatch('showNoticeMsg', {type: 'success', title: '系统提示', message: '密码修改成功!'})
      }).catch(() => {
        this.showChangePass = false
      })
    },
    handleClose () {
      this.showChangePass = false
    },
    showC () {
      this.showChangePass = true
    },
    logOut () {
      this.$store.dispatch('logOut')
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
@import '../../styles/theme.less';
.user-head-img {
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .iconfont {
    font-size: 1.8rem;
  }
}
.menu-item-children {
  max-height: 20rem;
  overflow-y: auto;
  position: absolute;
  display: none;
  width: 6rem;
  right: 0;
  flex-direction: column;
  background-color: transparent;
  top: @the-heading-height;
  // box-shadow: @size-shadow @color-shadow;
}
.the-user {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .iconfont, .icon-yifu {
    font-size: 1.5rem;
    font-weight: 800;
    margin-right: 5px;
  }
  .icon-yifu {
    cursor: pointer;
  }
}
.menu_div:hover {
  color: @color-main;
  .menu-item-children {
    display: flex;
  }
}
.the-user {
  color: #666;
}
.menu-item-children {
  background: #f8f8f9;
}
.menu-item-children-item {
  color: #666;
  background: #f8f8f9;
  border-bottom: 3px solid #f8f8f8;
}
.user-head-img {
  .iconfont {
    color: #666;
  }
}
.the-user-detail {
  display: flex;
}

.menu-item_div_active {
  border-color: @color-main;
  color: @color-main;
}
.menu-item-children-item {
  height: 3rem;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.the-user:hover {
  .menu-item-children {
    display: flex;
  }
}

.user-name {
  font-size: 1.2rem;
  margin: 0 1.5rem 0 0.7rem;
  display: flex;
  align-items: center;
}
.white-theme {
  .the-user-detail {
    color: @color-norm-white;
  }
  .menu-item-children-item:hover {
    color: @color-bright-white;
  }
}
.dark-theme {
  .the-user-detail {
    color: @color-stress-dark;
  }
  .menu-item-children-item:hover {
    color: @color-bright-dark;
  }
}
</style>
